:root {
  --vh: 100vh;
  --vw: 100vw;
  --wrapper-width: 100vw;
  --content-width: 100%;
  --wrapper-padding: 88px;
  --block-padding: 42px;
  --font-color: #333;
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: left;
  color: #fff;
  color: var(--font-color);
  font-family: Arial, "Helvetica neue", "Helvetica", sans-serif;
  letter-spacing: -0.02em;
  font-weight: 400;
  opacity: 1;
}

#fullPage {
  background-color: #000000;
}

a {
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

.wrapper {
  color: #fff;
  max-width: var(--wrapper-width);
  margin: auto;
  padding: var(--wrapper-padding);
  margin-bottom: 0;
}

#preload {
  display: none;
}

.home {
  background-color: #000000;
}

.home .wrapper {
  position: relative;
  color: #fff;
  background-color: #000000;
  padding: 0px ;
  height: calc(var(--vh) / 1);
  overflow: hidden;
}

.tint {
  position: absolute;
  width: var(--wrapper-width);
  height: calc(var(--vh) / 1 );
  margin: 0 auto auto auto;
  opacity: 1;
  z-index: 1;
  transition: 0.3s;
}

.tint .loading div {
  background-size: cover;
  background-position: center center !important;
  background-repeat: no-repeat;
}

.wrapperSqueeze {
  max-width: 100%;
  /*min-height: calc(100vh - 96px);*/
  margin: auto !important;
  padding: 0 var(--wrapper-padding) 0 var(--wrapper-padding);
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 0s;
  min-height: calc(var(--vh) - 330px - (var(--wrapper-padding)*2));
}

.wrapperSqueeze.onPage {

  padding: 0px !important;
}

.wrapperSqueeze.onPage .about {
  background-color: #000000 !important;
  padding: 0px !important;
}

.home .wrapperSqueeze.alternate {
  background-color: #fff !important;
}

.wrapperFull {
  width: 100vw;
  /*min-height: calc(100vh - 96px);*/
  margin: auto;
  padding: 0 var(--wrapper-padding) 0 var(--wrapper-padding);
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 0s;
  min-height: calc(var(--vh) - 330px - (var(--wrapper-padding)*2));
}

/********/
/* MENU */
/********/

nav.menuTop ul {
  position: absolute;
  top: 44px;
  right: calc(var(--wrapper-padding)*1);
  margin: 0px;
  transform: translate(12px, 0px);
  list-style: none;
  text-align: right;
  z-index: 100;
  letter-spacing: -0.02em;
}

nav.menuTop ul li {
  display: inline;
  opacity: 0;
}

nav.menuTop ul li a {
  display: inline;
  color: #ffff66;
  font-size: 16px;
  font-weight: 600;
  transition: 0.3s;
  margin: 0 0px 0 24px;
  font-weight: 600;
  border-radius: 10px;
  border: solid 1px transparent;
  padding: 11px;
  transition: ease-in-out 0.3s;
}

nav.menuTop ul li a.langSel {
  color: #ffff66 !important;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s;
  margin: 0 0px 0 24px;
  font-weight: 600;
  border-radius: 10px;
  padding: 8px 8px 8px 8px;
  border: solid 1px #333;
  transition: ease-in-out 0.3s;
}

.home nav.menuTop ul li a.langSel {
  border: solid 1px #ffff66;
}

nav.menuTop ul li a.active {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  background: #ffff66;
  border-radius: 10px;
  padding: 11px;
}

.home nav.menuTop ul li a, .home nav.menuTop ul li div.langSel {
  color: #ffff66;
  /*text-shadow: 0px 0px 3px rgba(0,0,0,0.2);*/
}

.home nav.menuTop ul li a:hover, .home nav.menuTop ul li div.langSel:hover {
  background: transparent;
  color: #ffff66;
}

nav.menuTop ul li a:hover, nav.menuTop ul li div.langSel:hover {
  background: transparent;
  color: #ffff66 !important;
  border: solid 1px #ffff66 !important;
  border-radius: 10px;
}

nav.menuTop ul li:nth-child(1) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 0.6s;
}

nav.menuTop ul li:nth-child(2) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 0.9s;
}

nav.menuTop ul li:nth-child(3) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1.2s;
}

nav.menuTop ul li:nth-child(4) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1.5s;
}

nav.menuTop ul li:nth-child(5) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1.8s;
}

nav.menuTop ul li:nth-child(6) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 2.1s;
}

nav.menuTop ul li:nth-child(7) {
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 2.1s;
}

nav.menuTop .langSel {
  cursor: pointer;
}

.btnHamburger {
  cursor: pointer;
  display: none;
}

.home .btnHamburger {
  color: #fff;
  font-size: 32px;
}

#logo {
  font-size: 32px;
  position: absolute;
  top: 30px;
  left: var(--wrapper-padding);
  width: auto;
  z-index: 10000;
  font-weight: bold !important;
}

#logo a {
  font-size: 32px;
  padding-right: 12px;
  color: inherit;
  line-height: 1;
  color: #ffff66;
}


.home #logo a {
  font-family:  Arial, "Times New Roman", serif;
  padding-right: 12px;
  color: #ffff66;
  animation: fade-in-slide-down forwards 0.6s ease-in-out;
}

.home #logo a span {
  font-size: 120px !important;;
  color: #ffff66;
  border-bottom: solid 0px #333;
}

.home #logo .bar {
}

.home #logo a b {
  transition: 0.3s;
}

/*********/
/* QUOTE */
/*********/

.headerQuote {
  position: absolute;
  bottom: calc(var(--wrapper-padding)*1);
  z-index: 100;
  font-family: Arial, "Helvetica neue", "Helvetica", sans-serif;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 24px;
  width: var(--wrapper-width);
  color: #fff;
  line-height: 1.2;
}

.headerQuote div.text {
  opacity: 0;
  display: flexbox;
  animation: fade-in-slide forwards 1s ease-in-out;
  animation-delay: 0.3s;
  font-family: Arial, "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  max-width: 480px;
  margin: auto 0 auto calc(var(--wrapper-padding)*1);
  text-align: left;
  line-height: 1.2;
  padding: 0.5em 0 0.5em 1.3em;
  /*text-shadow: 1px 1px 20px rgba(0,0,0,0.8);*/
  text-indent: -0.5em;
  border-left: 9px solid rgba(255,255,255,0.1);
}

.myQuoteStrike {
  /*text-decoration: line-through;
  color: #7E5563;*/
}

.headerQuote div.text span {
  transition: 1s;
}

.headerQuote div.by {
  display: flexbox;
  letter-spacing: 0px;
  text-indent: 0;
  font-size: 16px;
  font-family: Georgia, "Arial", serif;
  font-weight: 500;
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in;
  animation-delay: 0.5s;
  display: block;
  padding: 16px 0 0 0;
  font-style: italic;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}

.headerImage {
  width: 100%;
  opacity: 0;
}

.fadeOut {
  animation: fade-out 0.6s forwards;
}


/***********/
/* GENERAL */
/***********/

.anchor {
  padding: 0;
  font-size: 42px !important;
  line-height: 72px !important;
  font-family:  Arial, "Times New Roman", serif;
  font-weight: 600;
  text-align: left;
  max-width: 600px !important;
  color: #fff;
  user-select: none;
}

.anchor u {
	text-decoration: none;
  color: #fff;
	/*box-shadow: inset 0 0px 0 white, inset 0 -18px 0 #f5f5f5;*/
}

.anchor u.flash {
	text-decoration: none;
	/*box-shadow: inset 0px 0px 0 white, inset 0 -18px 0 #f5f5f5;*/
}

blockquote {
  border-left: 6px solid #eee;
  margin: 0px;
  padding: 3px 0 6px 24px;
}

.spacer {
  width: 0px;
  height: calc(var(--wrapper-padding)*1);
}

.spacerHalf {
  height: calc(var(--wrapper-padding) /2);
  clear: both;
}

.sectionTitle {
  display: flexbox;
  position: relative;
  max-width: var(--content-width);
  margin-bottom: 40px;
}

/*****************/
/* ABOUT SECTION */
/*****************/

.about {
  width: 100%;
  max-width: 100% !important;
  margin: auto !important;
  color: #333;
  background-color: #fff !important;
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*1) 0;
}

.home .about {
    width: 100% !important;
}

.about .anchor, #about {
  padding-right: 30px;
  color: #fff !important;
}

.home  #about {
  padding-right: 30px;
  color: #333 !important;
}

.about h1, .about p {
  color: #fff !important;
}

.home .about h1, .home .about p {
  color: #333 !important;
}

.about h1 {
  font-size: 36px;
  line-height: 54px;
  font-family: Arial, "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
}

.about ul {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.about ul li:nth-child(even) {
  background-color: #222;
}

.about ul li {
  padding: 30px !important;
  background-color: transparent;
  width: 100%;
  list-style: none;
  margin-bottom: 0px;
}

.about .blockFull {
  max-width: 100% !important;
  margin: auto !important;
  justify-content: space-between !important;
  align-items: top !important;
  align-content: top !important;
}

.about .blockText {
  margin-top: 0px;
  width: auto;
  padding: 0px 80px 0 0;
  border-left: solid 0px #eee;
  font-size: 21px;
  line-height: 36px;
  color: #fff;
}

.about .blockText h1 {
  font-size: 42px;
}


.about .blockText p {
  display: block;
  width: 100%;
}

.blockLeft {
  display: flex;
  position: relative;
  padding: 0px 0px 0 0px;
  max-width: 700px;
  /* white-space: nowrap; */
}

.credits {
  font-size: 14px;
  line-height: 22px;
}


.blockLeft .image .loading {
  border-radius: 10px;
  margin: 50px 0 0 0 !important;
}

.blockLeft .image .loading div {
  border-radius: 6px;
}

.about .readMore  {
  position: absolute;
  transform: translate(0px, -86px);
  font-size: 14px;
  padding: 16px;
  line-height: 16px;
  border-radius: 6px;
  font-weight: 600;
  background-color: #7E5563;
  color: #fff;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.about a.readMore:hover {
  background-color: #333;
  color: #fff;
}

.about p.end {
  transform: translate(0px, -96px);
  height: 70px;
  background: linear-gradient(to top, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.6) 80%,rgba(255,255,255,0) 100%); 
}

.about .authorBubble {
  margin-top: 28px !important;
}


/* TOUR SECTION */

.tour {
  width: 100%;
  max-width: 100% !important;
  margin: auto !important;
  color: #fff;
  padding: calc(var(--block-padding)*0) 0 calc(var(--block-padding)*1) 0;
}

.tour .anchor {
  padding-right: 30px;
  color: #fff;
}

.tour h1, .tour p {
  color: #fff !important;
}

.tour h1 {
  font-size: 36px;
  line-height: 54px;
  font-family: Arial, "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
}

.tour ul {
  width: 100% !important;
  margin: 0px;
  padding: 0px;
}

.tour ul li:nth-child(even) {
  background-color: #181818 !important;
}

.tour ul li {
  padding: 30px !important;
  line-height: 1.8;
  background-color: transparent;
  width: 100% !important;
  list-style: none;
  margin-bottom: 0px;
}


/*******************/
/* BLOCK 2 COLUMNS */
/*******************/

.blockFull {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  max-width: 100%;
}

.blockLeft {
  display: flexbox;
  position: relative;
  padding: 0px 0px var(--block-padding) 0px;
  max-width: 500px;
  /* white-space: nowrap; */
  color: #fff !important;
}

.blockLeft .authorBubble {
  padding-top: 7px;
}

.blockText {
  display: flexbox;
  margin-top: 9px;
  width: 100%;
  padding: 10px 0 0 0;
  border-left: solid 0px #eee;
  font-size: 21px;
  line-height: 36px;
  color: #fff;
}

.blockText p {
  display: block;
  max-width: 520px;
}

.blockText p a {
  color: #333;
  border-bottom: solid 2px #333;
  transition: ease-in-out 0.3s;
}

.blockText p a:hover {
  color: #fff;
  border-bottom: solid 2px #fff;
}

.blockText h2 {
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  font-family: Arial, "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
  margin: 0px;
}

.blockText li {
  padding: 0 0 14px 0;
}

.blockText p:first-child {
  margin-top: 0px;
}

.blockText p img {
  width: 100%;
}

.blockText .image .loading {
  position: relative;
  width: 100%;
  height: 320px;
  background-color: #000;
  margin: 10px 0 36px 0;
}

.blockLeft .image .loading {
  position: relative;
  width: 510px;
  height: 590px;
  background-color: #000;
  margin: 48px 48px 48px 0;
}

/****************/
/* TESTIMONIAL  */
/****************/

.testimonials {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-items: flex-end;
  left: 0px;
  width: 100% !important;
  bottom: 40px !important;
  margin-top: 0px;
}

.testimonials .navigation {
  position: absolute;
  display: flex;
  top: -12px;
  left: calc((var(--vw)/2));
  margin: auto !important;
  z-index: 100;
  display: none;
}

.testimonials .navigation .dot {
  font-size: 50px;
  color: #333;
  display: flex;
  cursor: pointer;
  letter-spacing: 0px;
  transition: 0.6s;
}

.testimonials .navigation .dot.active {
  font-size: 50px;
  color: #fff;
  display: flex;
}

.testimonials .quoteSign {
  display: none !important;
  font-family: "Arial", 'Times New Roman', serif;
  font-style: italic;
  color: #333;
  position: absolute;
  content: "“";
  transform: translate(10px, 28px);
  font-size: 200px;
  z-index: 0;
}

.testimonials .testimonial {
  background-color: rgba(0,0,0,0.85);
  bottom: 0px;
  left: 15%;
  padding: 20px 30px 20px 30px; 
  margin: auto !important;
  border-radius: 6px;
  /*opacity: 0;*/
}

.testimonials .testimonial .inLine {
  display: flex;
  color: #fff;
  max-width: 800px;
  justify-content: center;
}


.testimonials .testimonial:nth-child(2) {
  animation: fade-in-slide 0.9s forwards;
}

.testimonials .testimonial .loading {
  margin: 10px 0 0 0;
  position: relative;
  width: 42px !important;
  height: 42px !important;
  right: 0px;
  border-radius: 100%;
}

.testimonials .testimonial .loading div {
  border-radius: 100%;
  border: solid #ff0000 4px;
  width: 42px !important;
  height: 42px !important;
}

.testimonials .testimonial .byLine {
  display: none;
  font-size: 14px;
  position: relative;
  font-weight: 600;
}

.testimonials .testimonial.myFadeIn {
  animation: fade-in-slide 0.9s forwards;
}

.testimonials .testimonial.myFadeOut {
  animation: fade-out-slide 0.3s forwards;
}

.testimonials .testimonial div {
  font-family: Arial, 'Times New Roman', serif;
  font-size: 20px !important;
  line-height: 24px;
  font-weight: bold !important;
  color: #fff;
  border: none;
  padding: 0px !important;
  margin: 0px 0px 12px 0 !important;
  font-style: italic;
  border-radius: 20px;
  border-bottom-right-radius: 3px;
  white-space: normal;
}

.testimonials .testimonial blockquote a {
  color: #fff;
  text-decoration: underline;
}

.testimonials .testimonial blockquote strong {
  font-size: 18px;
}




/*************/
/* QUESTIONS */
/*************/

.questions {
  position: absolute;
  z-index: 30000;
  top: 80px;
  margin: auto;
  width: 100vw !important;
  /*background: linear-gradient(180deg, #ee6b60 0% , #ee5b4f 100%);*/
  background: linear-gradient(-360deg, #f5f5f5 0% , #f5f5f5 100%);
  height: calc(100vh - 80px);
  clip-path: url(#wave);
}

.wave {
  position: absolute;
}

.questions .navigation {
  position: absolute;
  width: 100% !important;
  text-align: center !important;
  left: auto !important;
  top: auto !important;
  bottom: 10px !important;
  justify-content: center !important;
  display: flex;
  transform: none !important;
  right: auto !important;
  z-index: 100;
}

.questions .question {
  position: absolute !important;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px !important;
  margin: 0px;
  height: calc(100vh - 100px) !important;
  max-width: auto !important;
  opacity: 0;
}

.questions .question:nth-child(2) {
  animation: fade-in-slide 0.9s forwards;
}

.questions .navigation .dot {
  color: rgba(0,0,0,0.2);
  font-size: 60px;
  cursor: pointer;
}

.questions .navigation .dot.active {
  color: #333;
}

.questions .question.myFadeIn {
  animation: fade-in-slide 0.9s forwards;
}

.questions .question.myFadeOut {
  animation: fade-out-slide 0.3s forwards;
}

.questions .question .content {
  margin: auto !important;
  max-width: 1000px !important;

  padding: 0 var(--wrapper-padding) 0 var(--wrapper-padding);
}

.questions .question p {
  border-left: none;
  color: #333 !important;
  font-style: normal;
  text-align: center;
  font-size: 78px !important;
  font-family: Arial, helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2 !important;
  padding: 0px 0px 0px 0px !important;
}

.questions .question p strong {
  color: #ee6b60;
  font-weight: 700;
}

.questions .question {
  margin: auto !important;
  width: 100% !important;
}

.rocket {
  position: absolute;
  z-index: 300000;
  left: auto;
  text-align: center;
  width: 100vw;
  color: #333;
  top: 200px !important;
  overflow: hidden;
}

.rocket .icon {
  color: #333;
  font-family: 'Times New Roman', Arial, serif;
  font-size: 200px;
  opacity: 1;
  line-height: auto !important;
  animation: heartbeat 1s infinite forwards linear !important;
}

.rocket .animate {
  /*animation: rotate 0.9s forwards;*/
}

/*****************/
/* AUTHOR BUBBLE */
/*****************/

.authorBubble {
  display: flex;
  align-items: center;
  margin: 42px 0 0px 0;
}

.authorBubble .loading {
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
  background-color: #f5f5f5;
  border-radius: 100% !important;
  display: flexbox;
  object-fit: cover;
  border: solid 0px #ee5b4f;
}

.authorBubble .img {
  border-radius: 100%;
  overflow: hidden;
}

.authorName {
  display: flexbox;
  font-size: 16px;
  padding: 0 0 0 12px;
  line-height: 1.2;
  font-weight: 700;
}

.authorName p {
  padding: 0;
  margin: 0;
}

.authorName span {
  display: flexbox;
  font-size: 12px;
  color: #aaa;
  font-weight: 600;
}

.authorName a {
  display: flexbox;
  border-bottom: 0px !important;
  font-size: 12px;
  color: #333 !important;
  font-weight: 600;
  font-weight: 600;
}

/****************/
/* WORK SECTION */
/****************/


.work {
  padding: 0 0 calc(var(--block-padding)*1) 0;
}

.work .projects {
  max-width: 100% !important;
  width: 100%;
  margin: auto !important;
}

.work .project {
  padding: 0px;
  width: auto !important;
  max-width: 100% !important;
  background-color: transparent;
}

.work .project .content {
  max-width: 600px;
}

.work .project p, .work .project .anchor {
  width: 100% !important;
  margin-top: 0px;
}

.links {
  padding: 20px 0px 20px 0px;
  border-radius: 10px;
}

.projectsBox .links {
  padding: 0px 0px 20px 0px;
}

.links ul {
  margin: 0px;
  padding: 0px;
  line-height: 2;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: left;
  justify-content: left;
  text-align: center;
  z-index: 2000;
}

.links li {
  padding: 0 8px 0 8px;
}

.testimonial li {
  padding: 0 20px 0px 0px;
}

.links li a {
  color: #ffffff;
}

.links li a img {
  height: 26px;
  width: auto;
  cursor: pointer;
}

.work .project .banner, .work .project .banner .loadingBanner {
  width: 400px !important;
  height: 400px !important;
  margin-top: 9px;
  margin-right: 80px;
  background-color: transparent;
}

.work .project .banner {
  height: 500px !important;
}

.work .small p {
  color: #fff;
  text-align: center;
  font-weight: bold;
  margin-top: 12px;
  font-size: 14px !important;
}

.flexMe {
  display: flex !important;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flexMe .tracklist {
  color: #fff;
  padding: 20px 0px 40px 40px;
  line-height: 2;
}

.flexMe .tracklist ol, .flexMe .tracklist ol li {
  list-style-position: inside;
  padding: 0px;
  margin: 0px;
}

.projectsPage {
  margin: auto !important;
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*0) 0;
}

.home .projectsPage {
  margin: auto !important;
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*2) 0;
}

/***************/
/* POST OBJECT */
/***************/

.posts {
  max-width: var(--content-width);
  margin: 0px 0 calc(var(--block-padding)*2) 0;
}

.post {
  font-size: 21px;
  line-height: 36px;
  color: #fff;
}

.post h1 {
  color: #fff;
  /*color: #544c57;*/
  font-size: 60px;
  line-height: 72px;
  font-family: Arial, "Helvetica neue", "Helvetica", sans-serif;
  font-family: Arial, 'Times New Roman', serif;
  font-weight: 700;
  margin: 0 0 0 0;
  transition: ease-in-out 0.3s;
}
.post h1 u {
  text-decoration: none;
  /*box-shadow: inset 0px 0px 0 white, inset 0 -18px 0 #eee;*/
}

.post h2 {
  color: #fff;
  font-size: 36px;
  line-height: 39px;
  font-weight: 600;
  font-family: Arial, "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
  margin: calc(var(--block-padding) *1.2) 0 0px 0;
}

.post h3 {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  font-family: Arial, "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
  margin: 36px 0 30px 0;
}

.post .excerpt {
  color: #fff;
  padding-top: 0px;
  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
  margin: 0px 0 0px 0px;
}

.posts img.banner {
  max-width: 100%;
}

.btnBack {
  position: absolute;
  transform: translate(0px, -40px);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0px;
  line-height: 1;
  font-size: 18px;
  color: #777;
  border-radius: 100%;
}

/****************/
/* GRID OBJECTS */
/****************/

.grid {
  max-width: 100% !important;
  display: flex;
  margin: auto !important;
  align-items: top;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.grid .post .content, .post .grid .end  {
  display: none;
}

.grid .post {
  position: relative;
  display: flex;
  width: 280px;
  align-items: top;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin-bottom: 40px;
  margin-right: 12px;
}

.grid .post .image {
  
}

.grid .post .image .box {
  text-align: right;
}

.grid .post .image .box .loading {
  position: relative;
  width: 280px;
  height: 160px;
  margin: 0 24px 6px 0 ;
  background-color: transparent;
  transition: 0.3s;
  filter: grayscale(20%);
}

.grid .post .image .box .loading div {
  border-radius: 8px;
}

.grid .post .button {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 40%;
  background-image: url("assets/btn-play-trans.png");
  background-repeat: no-repeat;
  background-position: center 20%;
  opacity: 0.8;
  z-index: 20000;
  transition: 0.3s;
}

.grid .post .button:hover {
  transform: scale(1.05);
}

.grid .post .image img {
  width: 100%;
  height: 190px !important;
  object-fit: cover;
  margin: 0 auto 0 0;
  filter: grayscale(100%);
  
  border-radius: 8px !important;
}

.grid .post h1 {
  font-size: 24px;
  line-height: 1.8;
  margin: 0 0 0 0px;
  padding-bottom: 0px;
  color: #fff;
}

.grid .post a.title h1:hover {
  color: #fff;
}

.grid .post .excerpt {
  color: #fff;
  padding-top: 0px;
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
  margin: 0px 0 0px 0px;
}

.grid .post .excerpt p {
  padding: 0px !important;
  margin: 0px !important;
}

.grid .post .text {
  display: flexbox;
  position: relative;
  width: 100%;
  margin: 0 0px 0 0px;
}

.grid .post .readMore  {
  display: none;
  position: absolute;
  transform: translate(0px, -30px);
  font-size: 14px;
  padding: 16px;
  line-height: 16px;
  border-radius: 6px;
  font-weight: bold;
  background-color: #eee;
  color: #fff;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.grid .post a.readMore:hover {
  background-color: #ee5b4f;
  color: #fff;
}

.relatedContent {
  padding: 0px 30px 0px 30px;
  margin-top: 50px;
  border-radius: 7px;
}

/****************/
/* LIST OBJECTS */
/****************/

.list  {
  display: flex;
  flex-direction: column;
  max-width: var(--content-width);
}

.list .post  {
  display: flex;
  max-width: var(--content-width);
}

.list .post a.title h1 {
  color: #fff;
}

.list .post a.title h1:hover {
  color: #fff;
}

.list .post .text {
  display: flexbox;
  position: relative;
  width: 70%;
}

.list .post .text hr {
  padding: 20px 0 0px 0;
  border: none;
  text-align: left;
  color: #ccc;
  font-family: Georgia, 'Times New Roman', Arial, serif;
}

.list .post .text hr::after {
  content: "***";
}

.list .post .text blockquote {
  border-left: 6px solid #eee;
  margin: 0px;
  padding: 3px 0 6px 24px;
  font-style: italic;
}


.list .post .text .wp-block-table {
  padding: 20px 0px 20px 0;
  margin: 0px;
}

.list .post .text .wp-block-table td {
  padding: 14px;
  background-color: #eee;
  font-size: 18px;
}

.list .post .text p.end {
  transform: translate(0px, -120px);
  height: 100px;
  background: linear-gradient(to top, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0) 80%); 
}

.list .post .text a.readMore {
  position: absolute;
  transform: translate(0px, -100px);
  font-size: 14px;
  padding: 16px;
  line-height: 16px;
  border-radius: 6px;
  font-weight: bold;
  background-color: #eee;
  color: #fff;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  transition: 0.3s;
}

.list .post .text a.readMore:hover {
  background-color: #ee5b4f;
  color: #fff;
}

.list .post .image .box .loading {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.list .post .image {
  display: flexbox;
  border: none;
  color: #fff;
  padding: 32px 0 0 60px;
  margin: 0px;
  text-align: right;
  width: 50%;
}

.list .post .image .box {
  width: 100%;
  margin: 0px 0 0px 0;
  line-height: 1;
}

.post .box img {
  width: 100%;
  transition: 0.3s ease-in-out;
}

.post .box a .loading:hover {
  transform: scale(1.02);
}

.list .post .image .details a {
  opacity: 0.3;
  font-size: 12px;
  color: #fff;
  transition: 0.3s ease-in-out;
  font-family: Arial, "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 600;
}

.list .post .image .details a:hover {
  color: #666;
  opacity: 1;
}

.list .post .image .details .link {
  transform: translate(-3px, 6px);
  width: 20px;
  color: #666;
}

/**************/
/* BLOG PAGE  */
/**************/

.blogPage{
  max-width: 100% !important;
  margin: auto auto 0px auto !important;
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*1) 0;
}

.projectsPage {
  margin: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*0) 0 !important;
}

.home .blogPage {
  max-width: 100% !important;
  width: 100%;
  margin: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*1) 0 !important;
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*0) 0;
}

.home .projectsPage {
  max-width: 100% !important;
  width: 100%;
  margin: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*1) 0 !important;
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*1) 0;
}

.notice {
  color: #999;
  font-weight: 400;
  font-style: italic;
  font-size: 22px;
}

/**************/
/* BLOG POST  */
/**************/

.blogPost {

  max-width: 100% !important;
  margin: auto !important;
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*1) 0;
}

.blogPost iframe {
  width: 800px !important;
  height: 450px !important;
}

.blogPost .post .text {
  width: 100% !important;
}

/*************/
/* CLIENTS  */
/*************/


.clients {
  display: flex;
  max-width: var(--wrapper-width) !important;
  margin: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: calc(var(--block-padding) *0.5);
}

.client {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  text-align: center;
}

.client .name {
  font-size: 12px;
  line-height: 1.3;
  padding: 8px 0px 0px 0;
  width: 120px;
  color: #fff;
}

.client .logo {
  position: relative;
  width: 100px;
  object-fit: cover;
  border-radius: 100%;
  height: 100px;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.client .banner {
  border-radius: 100%;
  overflow: hidden;
}

.client .banner .loading {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  transition: 0.6s;
}

.client .banner .loading div {
  border-radius: 100%;
  overflow: hidden;
}


.client .banner .loading:hover {
  transform: scale(1.05);
}

/*************/
/* PROJECTS  */
/*************/

.projects {
  max-width: var(--wrapper-width);
  margin: 0 0 80px 0;
}

.projects .project {
  position: relative;
}

.project {
  text-align: left;
  max-width: 200px !important;
  border-radius: 8px !important;
  background-color: #181818;
  padding: 32px;
}

.project .banner div {
  border-radius: 8px !important;
}

.project .banner img {
  width: 200px;
  transition: 0.3s;
}

.project .banner .loading {
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
  transition: 0.3s;
}

.project .banner .loadingBanner {
  position: relative;
  width: 100%;
  height: 565px;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.project .banner a .loading:hover {
  transform: scale(1.02);
}

.project .content {
  display: flex;
  max-width: 320px;
  padding: 0px;
  z-index: 300;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
}

.project h1 {
  color: #fff;
  font-size: 56px;
  line-height: 1.6 !important;
  font-family: Arial, 'Times New Roman', serif;
  font-weight: 700;
  margin: var(--block-padding) 0 0 0;
  transition: ease-in-out 0.3s;
}

.project a h1:hover {
  color: #666;
}

.project h3 {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  font-family: Arial, "Helvetica neue", "Helvetica", sans-serif;
  font-weight: 700;
  margin: 36px 0 30px 0;
}

.project .text {
  font-size: 21px;
  line-height: 36px;
  color: #fff;
}

.project .text a {
  color: #fff;
  border-bottom: solid 2px #666;
  transition: 0.3s;
}

.project .text a:hover {
  border-bottom: solid 2px #666;
}

.project .attachedImages {
  display: flexbox;
  text-align: right;
}

.project .attachedImages .attachedImage {
  position: relative;
  display: flexbox;
  transition: 0.3s;
  margin-top: calc(var(--block-padding) *1.5);
}

.project .attachedImages img {
  max-width: 100%;
  transition: 0.3s;
}

.project .attachedImages .attachedImage .loading {
  position: relative;
  width: 450px;
  height: 320px;
  background-color: #f5f5f5;
  transition: 0.3s;
}

.project .attachedImages .attachedImage a .loading:hover {
  transform: scale(1.02);
}

.project .attachedImages .attachedImage span {
  display: block;
  position: relative;
  z-index: 100;
  opacity: 1;
}

.project .attachedImages span img {
  margin-top: 12px;
  background-color: #333;
  border-radius: 100%;
  padding: 4px;
  width: 20px;
}

.project .content.single .text {
  padding-top: 30px;
}

.projectsPage .projectsBox {
    max-width: 100% !important;
    margin: auto;
}

.projectsPage .projectsBox .anchor {
  margin-bottom: 50px;
}


.projectsPage .projects {
  display: flex;
  max-width: 100%;
  margin: auto !important;
  align-items: top;
  justify-content: flex-start;
  align-self: auto;
  flex-direction: row;
  flex-flow: row wrap;
}

.projectsPage .project {
  width: 520px !important;
  flex-direction: row;
  justify-content: space-around;
  margin: 0px 20px 20px 0px;
}

.projectsPage .projects .project .banner {
  max-width: 100%;
}

.projectsPage .text {
  max-width: 100%;
}

.projectsPage .project .text h1 {

  max-width: 600px !important;
  font-size: 20px;
  color: #fff;
  margin-top: 0px;
  line-height: 1.3 !important;
}

.projectsPage .project .content  {
  margin-bottom: 0 !important;
  position: relative;
}

.projectsPage .content .text .excerpt, .projectsPage .content .text .excerpt p {
  display: block;
  font-size: 12px;
  color: #fff;
  padding-top: 0px;
  margin-top: 4px;
  letter-spacing: 0px;
  font-weight: 400;
  line-height: 1.2;
  z-index: 200;
}

.moreProjects {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}


.moreProjectsTitle {
  color: #fff;
  border-bottom: 1px #fff solid;
  text-decoration: none !important;
  font-size: 22px;
  line-height: 48px !important;
  margin-bottom: 20px;
}

.moreProjects .project {
  width: 105px !important;
  height: 105px !important;
  margin: 0px 12px 12px 0;
  transition: 0.3s;
}

.moreProjects .project .loading div {
  width: 105px;
  height: 105px;
  border-radius: 3px !important;
  transition: 0.3s;
}

.moreProjects .project .loading div:hover {
  transform: scale(1.02);
}

.projectsPage .blockFull .blockLeft .anchor {
  margin-top: 1px;
  color: #fff;
}

.projectsPage .blockFull .blockText {
  padding-top: 0px;
}

.projectsPage .blockFull .blockText p {
  width: 100%;
  padding-top: 5px;
}


/******************/
/* CONTACT BANNER */
/******************/

.contactBanner {
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1s;
  background: #f1f1f1;
  /*margin-top: calc(var(--block-padding)*2) !important;*/
  padding: calc(var(--block-padding)*0.4) var(--wrapper-padding) calc(var(--block-padding)*1.1) var(--wrapper-padding);
}

.contactBanner .content {
  max-width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: solid 0px #fff;
}

.contactBanner .text {
  padding: 18px 0 0 0;
  width: 50%;
  color: #777;
  margin-right: 0px;
  font-weight: 400;
  letter-spacing: -0.04em;
  font-size: 18px;
  line-height: 30px;
  margin: 20px auto 0px 0;
}

.contactBanner a  {
  color: #777 !important;
  text-decoration: none;
  border-bottom: solid 0px #777;
  transition: 0.3s;
}

.contactBanner u:hover, .contactBanner a:hover {
  color: #666 !important;
  border-bottom: solid 2px #666;
}

.contactBanner a {
  color: #fff;
}

.contactBanner .authorBubble  {
  margin: 0px;
}

.contactBanner .authorBubble .authorPic  {
  width: 100px;
  height: 100px;
  border: solid 8px rgba(255,255,255,0.1);
}

.contactBanner .authorBubble .authorName  {
  font-size: 16px;
  color: #fff;
}

.contactBanner .authorBubble .authorName span {
  color: #fff;
}

.contactBanner .authorBubble a {
  border-bottom: none;
  color: #aaa;
  transition: 0.3s;
}

.contactBanner .authorBubble a:hover {
  color: #fff;
}

.contactButton div {
  display: inline-block;
}

.contactButton .authorBubble .authorName {
  display: none;
}

.contactButton .authorBubble .loading {
    width: 32px !important;
    height: 32px !important;
    transform: translate(0px, 20px);
    border: solid 3px #333;
    transition: 0.3s;
}

.contactButton .authorBubble .loading:hover {
    transform: translate(0px, 20px) scale(1.1);
}

.contactButton a img {
  width: 32px;
  margin-left: 6px;
  border: solid 3px #333;
  transition: 0.3s;
  border-radius: 100%;
  background-color: #333;
  transform: translate(0px, 20px);
}

.contactButton a {
  border: none !important;
}

.contactButton a img:hover {
  transform: translate(0px, 20px) scale(1.1);
}

/*******************/
/* CONTACT PAGE */
/*******************/

.contact {
  max-width: 100%;
  margin: auto !important;
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*2.6) 0;
  font-size: 21px;
  line-height: 36px;
}

.contact p a {
  color: #fff;
  text-decoration: none !important;
  border-bottom: 2px solid #fff;
}

.contact p a:hover {
  color: #fff;
  text-decoration: none !important;
  border-bottom: 1px solid #fff;
}

.contact .blockText {
  margin-top: 99px;
}

.contact .blockLeft {
 margin: 0 60px 0 0;
}

/*******************/
/* PRIVACY PAGE */
/*******************/

.privacy {
  padding: calc(var(--block-padding)*1) 0 calc(var(--block-padding)*2) 0;
  font-size: 21px;
  line-height: 36px;
  color: #fff;
}

.privacy h2 {
  font-size: 32px;
  line-height: 39px;
  margin: 0;
}

.privacy .content {
  max-width: 60%;
}

.privacy p u, .privacy p a {
  color: #fff;
  text-decoration: none !important;
  border-bottom: 2px solid #fff;
  transition: 0.3s;
}

.privacy p u:hover, .privacy p a:hover {
  color: #fff;
  text-decoration: none !important;
  border-bottom: 2px solid #fff;
}

/**********/
/* FOOTER */
/**********/

.footer {
  opacity: 0;
  animation: fade-in forwards 0.8s ease-in-out;
  animation-delay: 1s;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--wrapper-padding);
  font-size: 12px;
  color: #777;
  background-color: #f1f1f1;
}


.footer .links a {
  font-weight: 700;
  color: #777;
  transition: 0.3s;
}

.footer .links a:hover {
  font-weight: 700;
  color: #aaa;
}

.footer .credits {
  font-weight: 400;
}



/* COOKIE BANNER */

.cookieContainer {
  position: absolute;
  z-index: 300000;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: rgba(0,0,0,0.6);
}

.cookieBanner {
  margin: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 300px !important;
  height: auto;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0px 100px rgba(0,0,0,0.5);
}

.cookieBanner .title {
  font-weight: 700;
  font-size: 12px;
}

.cookieBanner .desc {
  font-size: 14px;
}

.cookieBanner .links {
  color: #444 !important;
  font-size: 12px;
  text-align: center;
}

.cookieBanner .links a {
  color: #000 !important;
  border-bottom: solid #000 1px;
}

.cookieBanner .btnAccept {
  font-size: 16px;
  background-color: #000;
  display: block;
  text-align: center;
  padding: 12px;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 0 10px 0;
}

.cookieBanner .btnReject{
  font-size: 16px;
  background-color: #eee;
  display: block;
  text-align: center;
  padding: 12px;
  color: #777;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 0 10px 0;
}

/*******************/
/* LOADING SPINNER */
/*******************/

.spinner {
  margin: 100px 0 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

.loader {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: calc(50% - 2em);
}

.loader .spinner {
  margin: 0px;
}

.loader .spinner > div {
  background-color: rgba(255,255,255,0.6);
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
}

/**************/
/* ANIMATIONS */
/**************/

@keyframes fade-in{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes fade-out{
  0% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in-blur {
  0% {
    opacity: 0;
    filter: blur(2px);
  }
  100% {
    filter: blur(0px);
    opacity: 100;
  }
}

@keyframes fade-in-slide {
  0% {
    opacity: 0;
    transform: translate(0px, 80px);
  }
  90% {
    opacity: 100;
    transform: translate(0px, 1px);
  }
  100% {
    opacity: 100;
    transform: translate(0px, 0px);
  }
}


@keyframes fade-out-slide {
  0% {
    opacity: 100;
    transform: translate(0px, 0px);
  }
  90% {
    opacity: 0;
    transform: translate(0px, 29px);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 30px);
  }
}

@keyframes fade-in-slide-down {
  from {
    opacity: 0;
    transform: translate(0px, -50px);
  }
  to {
    opacity: 100;
    transform: translate(0px, 0px);
  }
}

@keyframes fade-in-slide-right {
  0% {
    opacity: 0;
    transform: translate(-20px, 0px);
  }
  80% {
    
  }

  100% {
    opacity: 100;
    transform: translate(0px, 0px);
  }
}

@keyframes heartbeat {
  0%
  {
    opacity: 1;
    transform: scale(1.05);
  }
  50%
  {
    opacity: 1;
    transform: scale(1);
  }
  100%
  {
    opacity: 1;
    transform: scale(1.05);
  }
}

@keyframes rotate {
  0%
  {
    opacity: 1;
    transform: rotate(0deg);
  }

  40%
  {
    opacity: 0;
  }

  100%
  {
    opacity: 1;
    transform: rotate(360deg);
  }
}

@keyframes rotateSmooth {
  0%
  {
    opacity: 1;
    transform: rotate(0deg);
  }

  40%
  {
    opacity: 1;
  }

  100%
  {
    opacity: 1;
    transform: rotate(360deg);
  }
}

@keyframes move {
  0%
  {
    opacity: 1;
    transform: translate(0px, -10px);
  }

  50%
  {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  100%
  {
    opacity: 1;
    transform: translate(0px, -10px);
  }
}


@keyframes sway {
  0%
  {
    opacity: 1;
    transform: scale(1);
  }

  50%
  {
    opacity: 1;
    transform: scale(1.05);
  }

  100%
  {
    opacity: 1;
    transform: scale(1);
  }
}